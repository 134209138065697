import React from 'react'
import Sidebar from '../Sidebar'
import './style.scss'

import Client from 'shopify-buy'

class ProductTemplateDetails extends React.Component {

  render() {

    const { product } = this.props.pageContext

    async function checkout () {
      // build a client
      const client = Client.buildClient({
        storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
        domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`,
      })
    
      // Fetch a single product by ID
      const productId = 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0LzYwMDc3NDk4MzY5ODk=';
  
      client.product.fetch(productId).then(() => {
        // Do something with the product
        client.checkout.create().then((checkout) => {
          // Do something with the checkout
          const checkoutId = checkout.id;
          
          console.log(checkoutId)
          
          const lineItemsToAdd = [
            {
              variantId: "Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zNzE4MjgzMDIxNTM1Nw==",
              quantity: 1,
              // customAttributes: [{key: "MyKey", value: "MyValue"}]
            }
          ];
  
          // Add an item to the checkout
          client.checkout.addLineItems(checkoutId, lineItemsToAdd).then((checkout) => {
            // Do something with the updated checkout
            console.log(checkout.lineItems); // Array with one additional line item
          });
  
          window.open(checkout.webUrl)
  
        });
      });
    }

    return (
      <div>
        <div className="content">
          <div className="content__inner">
            <div>
              <img src={product.images[0].originalSrc} alt={product.handle} />
              <h2 className="productDetail_title">{product.title}</h2>
              <div className="productDetail_description">{product.description}</div>
              <button onClick={checkout}>Buy for €{product.priceRange.minVariantPrice.amount}</button>
            </div>
          </div>
        </div>
        <Sidebar {...this.props} />
      </div>
    )
  }
}

export default ProductTemplateDetails