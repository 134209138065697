import React from "react"
import Helmet from 'react-helmet'
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import ProductTemplateDetails from '../components/ProductTemplateDetails'

class ProductTemplate extends React.Component {
  render(){

    const { product } = this.props.pageContext

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{product.title}</title>
            <meta name="description" content={product.description} />
          </Helmet>
          <ProductTemplateDetails {...this.props} />
        </div>
      </Layout>
    )
  }
}

export default ProductTemplate

export const pageQuery = graphql`
  {
    allShopifyProduct {
      edges {
        node {
          title
          shopifyId
          description
          handle
          images {
            originalSrc
          }
          priceRange {
            minVariantPrice {
              amount
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        subtitle
        copyright
        menu {
          label
          path
        }
        author {
          name
          twitter
        }
        disqusShortname
        url
      }
    }
  }
`